import { FolderApi, TabPageApi, SliderBladeApi, TextBladeApi } from 'tweakpane';
import { BindingApi, ButtonApi, FolderApiEvents } from '@tweakpane/core';
import * as EssentialsPlugin from '@tweakpane/plugin-essentials';
import { AnimationAction, AnimationClip } from 'three';

interface Animation {
    prompt: string;
    range: { min: number, max: number };
    generated: boolean;
    action?: AnimationAction;
    lastTime?: number;
    length: number;
}

export class SequencerPane {
    private _tab: TabPageApi;
    private _seekBar: SliderBladeApi;
    private _playing = false;
    private _toolbar: EssentialsPlugin.ButtonGridApi;
    private _promptBlade: BindingApi;
    private _toolbarButtonStates: boolean[] = [ false, false, false, true, false, false ];
    private _settings: { model: string; skeleton: boolean; aabb: boolean; autoRotate: boolean; autoPlay: boolean; };
    public animations: Animation[] = [{
        prompt: '',
        range: { min: 0, max: 100 },
        generated: false,
        length: 0
    }];
    
    private _isProgrammaticallyChanging = false;
    private _currentTrack = 0;

    private _lastAnimationIndex = 0;

    get playing() {
        return this._playing;
    }
    set playing(value: boolean) {
        if (!this._toolbarButtonStates[1])
            return;

        const playBtn = document.getElementById("play_btn");
        this._playing = value;
        const icon = value ? 
            `<i style="color:${this._toolbarButtonStates[1] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-pause"></i>`: 
            `<i style="color:${this._toolbarButtonStates[1] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-play"></i>`;
        if (playBtn) {
            playBtn.innerHTML = icon;
        }
    }

    get toolbarButtonStates() {
        return this._toolbarButtonStates;
    }
    set toolbarButtonStates(value: boolean[]) {
        this._toolbarButtonStates = value;
        this.updateButtonGridButtonWithIcons(this._toolbar, 
            [[ 
                `<i style="color:${this._toolbarButtonStates[0] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-backward-step"></i>`, 
                `<i style="color:${this._toolbarButtonStates[1] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-play"></i>`, 
                `<i style="color:${this._toolbarButtonStates[2] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-forward-step"></i>`,
                `<i style="color:${this._toolbarButtonStates[3] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-film"></i>` ,
                `<i style="color:${this._toolbarButtonStates[4] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-eraser"></i>`,
                `<i style="color:${this._toolbarButtonStates[5] ? 'var(--color-fg)': 'rgba(20, 20, 20, 0.2)'}" class="fa-solid fa-download"></i>`
            ]]);
    }

    get currentTrack() {
        return this._currentTrack;
    }

    set currentTrack(value: number) {
        this._currentTrack = value;
        const changed = this._currentTrack == value;
        this._isProgrammaticallyChanging = true;
        const folders = this._tab.children.filter((a) => a instanceof FolderApi) as FolderApi[];
        for (let i = 0; i < folders.length; i++) {
            console.log(`${i == value ? "Expanding" : "Collapsing"} folder ${i}`);
            folders[i].expanded = (i == value);
        }
        this._isProgrammaticallyChanging = false;
        if (!changed)
            this.onChanged();
    }

    onGenerateAnimation: (animation: Animation) => void = (animation: Animation) => 
    {
        console.log(`Generating animation for ${animation.prompt}...`);
    };

    onPlayAnimation: (startIndex: number) => void = (startIndex: number) =>
    {
        console.log(`Playing animation ${startIndex}`);
    };

    onExport: (prompt: string) => void = () => 
    {
        console.log("Exporting...");
    }

    onChanged: () => void = () => {};
    onClear: () => void = () => {};

    private getButtonGridButton(toolbar: EssentialsPlugin.ButtonGridApi, y: number, x: number) {
        const size = toolbar.controller.valueController.size;
        if ((x < 0) || (x >= size[0]) || (y < 0) || (y >= size[1]))
            return null;
        const parent = toolbar.controller.view.valueElement.children[0];
        if (!parent)
            return null;
        
        // Note: Can use the commented line below if you want the Button element
        // instead of the inner div
        // return parent.children[y * size[0] + x].children[0]

        // Note: This returns the inner div element of the Button element, 
        // use the commented line above if you want the Button element instead
        return parent.children[y * size[0] + x].children[0]?.children[0] as HTMLElement;
    }

    private setButtonGridButtonIds(toolbar: EssentialsPlugin.ButtonGridApi, ids: string[][]) {
        const size = toolbar.controller.valueController.size;
        console.log("toolbar size" + toolbar.controller);
        if (!size)
            return;
        if ((size[0] == ids[0].length) && (size[1] == ids.length))
            for (let y = 0; y < size[1]; y++) {
                for (let x = 0; x < size[0]; x++) {
                    const btn = this.getButtonGridButton(toolbar, y, x);
                    if (btn) 
                        btn.id = ids[y][x];
                }
            }
    }

    private setButtonTooltips(toolbar: EssentialsPlugin.ButtonGridApi, tooltips: string[][]) {
        const size = toolbar.controller.valueController.size;
        console.log("toolbar size" + toolbar.controller);
        if (!size)
            return;
        if ((size[0] == tooltips[0].length) && (size[1] == tooltips.length))
            for (let y = 0; y < size[1]; y++) {
                for (let x = 0; x < size[0]; x++) {
                    const btn = this.getButtonGridButton(toolbar, y, x);
                    if (btn) {
                        btn.classList.add("btn-icon-style");
                        btn.title = tooltips[y][x];
                    }
                }
            }
    }

    private updateButtonGridButtonWithIcons(toolbar: EssentialsPlugin.ButtonGridApi, ids: string[][]) {
        const size = toolbar.controller.valueController.size;
        console.log("toolbar size" + toolbar.controller);
        if (!size)
            return;
        if ((size[0] == ids[0].length) && (size[1] == ids.length))
            for (let y = 0; y < size[1]; y++) {
                for (let x = 0; x < size[0]; x++) {
                    const btn = this.getButtonGridButton(toolbar, y, x);
                    if (btn) {
                        btn.classList.add("btn-icon-style");
                        btn.innerHTML = ids[y][x];
                    }
                        
                }
            }
    }

    updateTime(time: number) {
        if (this.animations[this._currentTrack].action) {
            this._seekBar.value = time;
        }
    }

    pause()
    {
        this.playing = false;
    }

    clear()
    {
        this.pause();
        const folders = this._tab.children.filter((f) => f instanceof FolderApi) as FolderApi[];
        for (const f of folders) {
            this._tab.remove(f);
        }

        this._lastAnimationIndex = 0;
        this.animations[0].prompt = "";
        this._promptBlade.refresh();
        this._seekBar.value = 0;
        this._seekBar.disabled = true;
        this.onChanged();
        this.onClear();
    }

    reset()
    {
        this.pause();
        this._seekBar.value = 0;
        this.toolbarButtonStates = [ false, false, false, true, false, false ];
    }

    private async generateAnimation(animation: Animation) {
        await this.onGenerateAnimation(animation);
        if (this.animations[this._currentTrack].generated)
        {
            this._toolbarButtonStates[0] = true;
            this._toolbarButtonStates[1] = true;
            this._toolbarButtonStates[2] = true;
            this._toolbarButtonStates[4] = true;
            this._toolbarButtonStates[5] = true;

            this._seekBar.disabled = false;
            this._seekBar.value = 0;
            this._seekBar.min = animation.range.min;
            this._seekBar.max = animation.range.max;

            this.toolbarButtonStates = this._toolbarButtonStates;

            if (this._settings?.autoPlay)
            {                
                this.playing = true;
                if (this.playing)
                    this.onPlayAnimation(this._currentTrack);
                this.playing = this.playing;
            }
        }
    }

    constructor(tab: TabPageApi, settings: { model: string; skeleton: boolean; aabb: boolean; autoRotate: boolean; autoPlay: boolean; }) {
        this._tab = tab;
        this._settings = settings;

        this._promptBlade = tab.addBinding(this.animations[this._currentTrack], 'prompt');
        this._promptBlade.on('change', async (ev: FolderApiEvents['change']) => {
            this.generateAnimation(this.animations[this._currentTrack]);
        });
        const length_binding = tab.addBinding(this.animations[this._currentTrack], 'length', 
        {
            step: 1,
            min: 0,
            max: 30,
            value: 0,
            label: "length (auto)"
        });
        length_binding.on('change', async (ev: FolderApiEvents['change']) => {
            const value = this.animations[this._currentTrack].length;
            if (value == 0)
                length_binding.label = "length (auto)";
            else
                length_binding.label = "length";
        });
        // const slider = this._tab.addBlade({
        //     view: "slider",
        //     label: "length",
        //     min: 0,
        //     max: 15,
        //     value: 0
        // });

        this._tab.addBlade({
            view: "separator",
        });

        this._seekBar = this._tab.addBlade({
            view: 'slider',
            label: 'seek',
            min: 0,
            max: 1,
            value: 0,
            disabled: true,
        }) as SliderBladeApi;
        // Set the id so we can style it with CSS
        this._seekBar.controller.view.element.children[1].children[0].children[1].id = "seek";
        this._seekBar.element.onmousedown = (ev) => {
            this.playing = false;
        };
        this._seekBar.on('change', (ev: FolderApiEvents['change']) => {
            const index = this._currentTrack;
            const animation = this.animations[index];
            const value = ev.value as number;
            const time = value;
            if (animation.action) {
                // animation.action.play();
                const mixer = animation.action.getMixer();
                mixer.setTime(time);
            }
        });

        this._toolbar = this._tab.addBlade({
            view: 'buttongrid',
            size: [this._toolbarButtonStates.length, 1],
            cells: (x: number, y: number) => ({
              title: [
                ['Prev', 'Play', 'Next', 'Animate', 'Clear', 'Download']
              ][y][x],
            }),
        }) as EssentialsPlugin.ButtonGridApi;
        this.setButtonGridButtonIds(this._toolbar, [[ "rw_btn", "play_btn", "ff_btn", "animate_btn", "clear_btn", "download_btn" ]]);
        this.setButtonTooltips(this._toolbar, [[ "Previous frame", "Play/Pause", "Next frame", "Animate", "Clear", "Download" ]]);
        this.toolbarButtonStates = [ false, false, false, true, false, false ];
        
        this._toolbar.on('click', (ev) => {
            
            switch (ev.index[0]) {
                case 0: /* Prev frame */
                    {
                        // if this button is disabled, return
                        if (!this._toolbarButtonStates[0])
                            return;
                        
                        // If the animation is playing, pause it
                        if (this.playing)
                            this.playing = false;
                        
                        // Get the current clip
                        const clip = this.animations[this._currentTrack].action?.getClip();
                        if (!clip)
                            return;
                        
                        // Find the frametime subtracting the times of the first 2 frames in the first track
                        const frameTime = clip.tracks[0].times[1] - clip.tracks[0].times[0];

                        const mixer = this.animations[this._currentTrack].action?.getMixer();
                        if (mixer)
                        {
                            let currTime = mixer.time;
                            currTime -= frameTime;
                            currTime = Math.max(0, currTime);
                            mixer.setTime(currTime);
                            this._seekBar.value = currTime;
                        }
                    }
                    
                    break;
                case 1: /* Play/Pause */
                    this.playing = !this.playing;
                    if (this.playing)
                        this.onPlayAnimation(this._currentTrack);
                    this.playing = this.playing;
                    break;
                case 2: /* Next frame */
                    {
                        // if this button is disabled, return
                        if (!this._toolbarButtonStates[2])
                            return;
                        
                        // If the animation is playing, pause it
                        if (this.playing)
                            this.playing = false;
                        
                        // Get the current clip
                        const clip = this.animations[this._currentTrack].action?.getClip();
                        if (!clip)
                            return;
                        
                        // Find the frametime subtracting the times of the first 2 frames in the first track
                        const frameTime = clip.tracks[0].times[1] - clip.tracks[0].times[0];
                        const duration = clip.duration;
                        const mixer = this.animations[this._currentTrack].action?.getMixer();
                        if (mixer)
                        {
                            let currTime = mixer.time;
                            currTime += frameTime;
                            currTime = Math.min(duration, currTime);
                            mixer.setTime(currTime);
                            this._seekBar.value = currTime;
                        }
                    }
                    break;
                case 3: /* Animate */
                    this.generateAnimation(this.animations[this._currentTrack]);
                    break;

                case 4: /* Clear */
                    this.clear();
                    break;

                case 5: /* Download */
                    if (this.animations[this._currentTrack].generated)
                    {
                        const prompt = this.animations[this._currentTrack].prompt;
                        this.onExport(prompt);
                    }
                    break;
            }
        });
    }
};